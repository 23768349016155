import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './index.css';

const Player2 = () => {
  const { streamId } = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [stream, setStream] = useState(null);
  const [loading, setLoading] = useState(true);

  const createStream = async (streamId) => {
    setLoading(true);
    if(streamId){
      const link = `https://sportstransfer.xyz/bongls/?id=${streamId}`;
      setStream(link);
    }
    setLoading(false);
  }

  const updateScreenSize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenSize);
    createStream(streamId);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <iframe
          title="Video Player"
          width={screenWidth}
          height={screenHeight}
          src={stream}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
);
};

export default Player2;
